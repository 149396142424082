export function useDateUtils() {
  const dateStore = useDateStore()
  const { publicHolidays } = storeToRefs(dateStore)

  const convertDDMMYYYYToJsDate = (date: string): Date => {
    if (!date || date.split('-').length !== 3) return new Date('1970-01-01')

    const [day, month, year] = date.split('-').map(Number)

    return new Date(year, month - 1, day)
  }

  const convertYYYYMMDDToJsDate = (date: string): Date => {
    if (!date || date === '' || date.split('-').length !== 3) return new Date('1970-01-01')

    const [year, month, day] = date.split('-').map(Number)

    return new Date(year, month - 1, day)
  }

  const getEarliestPossibleDeliveryDate = async (): Promise<Date> => {
    const options = { timeZone: 'Europe/Berlin' }
    const today = createNewGermanDate()
    const dateToday = today.toLocaleDateString('de-DE', options)
    const timeNow = today.toLocaleTimeString('de-DE', options)
    const dateParam = `${dateToday.split('.').reverse().join('-')}%20${timeNow}`

    try {
      const data = await $fetch('/api/date', {
        params: { date: dateParam },
      })

      return convertYYYYMMDDToJsDate(data as string)
    } catch (error) {
      console.error(error)
      return new Date('1970-01-01')
    }
  }

  const getAvailableTomorrowDate = async (): Promise<Date> => {
    const today = createNewGermanDate()
    today.setHours(0, 0, 0, 0)
    const dateToday = today.toLocaleDateString('de-DE')
    const timeNow = today.toLocaleTimeString('de-DE')
    const dateParam = `${dateToday.split('.').reverse().join('-')}%20${timeNow}`

    try {
      const data = await $fetch('/api/date', {
        params: { date: dateParam },
      })

      return convertYYYYMMDDToJsDate(data as string)
    } catch (error) {
      console.error(error)
      return new Date('1970-01-01')
    }
  }

  const isWeekday = (date: Date): boolean => {
    const dayOfWeek = date.getDay()

    return dayOfWeek >= 1 && dayOfWeek <= 5
  }

  const isPublicHoliday = (date: Date): boolean => {
    return publicHolidays.value.some(
      holiday => holiday.toISOString().split('T')[0] === date.toISOString().split('T')[0],
    )
  }

  const createNewGermanDate = (): Date => {
    const originalDate = new Date()
    const localTime = originalDate.getTime()
    const localOffset = originalDate.getTimezoneOffset() * 60000
    const utc = localTime + localOffset
    const offset = +2 // UTC of Germany CEST (Central European Summer Time)
    const german = utc + 3600000 * offset
    return new Date(german)
  }

  const formatDateString = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'Europe/Berlin',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }
    return date.toLocaleDateString('de-DE', options)
  }

  return {
    convertDDMMYYYYToJsDate,
    convertYYYYMMDDToJsDate,
    getEarliestPossibleDeliveryDate,
    isWeekday,
    isPublicHoliday,
    createNewGermanDate,
    formatDateString,
    getAvailableTomorrowDate,
  }
}
