export const useDateStore = defineStore('date-store', () => {
  const { convertDDMMYYYYToJsDate } = useDateUtils()

  const publicHolidays = ref<Date[]>([])

  async function getPublicHolidaysFromApi(): Promise<void> {
    if (publicHolidays.value.length !== 0) return // TODO: Probably remove this check

    try {
      const { data } = await useFetch(() => '/api/holiday')

      if (!Array.isArray(data.value)) throw new Error('Data is not an array')

      publicHolidays.value = data.value.map((holiday: string) => convertDDMMYYYYToJsDate(holiday))
    } catch (error) {
      console.error(error)
    }
  }

  return {
    publicHolidays,
    getPublicHolidaysFromApi,
  }
})
